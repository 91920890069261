import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { walletType } from '../../enums/wallets';
import starterLogo from '../../assets/images/bonuses/starter.png';
import subscriptionLogo from '../../assets/images/logos/one-life-logо.png';
import { ReactComponent as ShopIcon } from '../../assets/images/layout-svg-icons/topicn-cart.svg';
import { orderItemTypes } from '../../enums/orders';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ShoppingCart = function ShoppingCart(props) {
  var shoppingCart = props.shoppingCart,
    cashWallet = props.cashWallet;
  var history = useHistory();
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    dropdownOpened = _useState2[0],
    setDropdownOpened = _useState2[1];
  var hasItems = !!shoppingCart.items.length;
  var isNegativeCashBalance = (cashWallet === null || cashWallet === void 0 ? void 0 : cashWallet.balance) < 0;
  var showDropdown = function showDropdown() {
    return hasItems ? setDropdownOpened(true) : history.push('/shop');
  };
  var hideDropdown = function hideDropdown() {
    var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var currentTarget = event.currentTarget,
      relatedTarget = event.relatedTarget;
    if (currentTarget !== null && currentTarget !== void 0 && currentTarget.contains(relatedTarget)) return;
    setDropdownOpened(false);
  };
  var goToShoppingCard = function goToShoppingCard(event) {
    event.stopPropagation();
    hideDropdown();
    history.push('/shop/cart');
  };
  var getItemPictureUrl = function getItemPictureUrl(item) {
    var url = item.type === orderItemTypes.Package ? starterLogo : subscriptionLogo;
    if (item.picThumbnailUrl) url = item.picThumbnailUrl;else if (item.pictureUrl) url = item.pictureUrl;
    return url;
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: "vk-header-shop-wrapper",
    onClick: showDropdown,
    onBlur: hideDropdown
  }, ___EmotionJSX(NavLink, {
    to: "/shop/shopping-cart",
    onClick: function onClick(event) {
      return event.preventDefault();
    }
  }, ___EmotionJSX("div", {
    className: "vk-header-shop-inner"
  }, ___EmotionJSX("div", {
    className: "vk-header-shop-inner-wrap"
  }, ___EmotionJSX(ShopIcon, null), hasItems && ___EmotionJSX("div", {
    className: "vk-header-shop-badge"
  }, shoppingCart.items.length)))), dropdownOpened && ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-wrap",
    role: "menuitem",
    tabIndex: 0
  }, ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-header"
  }, "Shopping Cart (", shoppingCart.items.length, ")"), ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-body-head"
  }, ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-body-label"
  }, "Item"), ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-body-label"
  }, "Price")), ___EmotionJSX("div", {
    className: "vk-header-shop-items-wrap"
  }, shoppingCart.items.map(function (el, i) {
    return ___EmotionJSX("div", {
      className: "vk-header-shop-item",
      key: "".concat(el.type === orderItemTypes.Package ? el.catalogueItemId : el.id, "-").concat(i)
    }, ___EmotionJSX("div", {
      className: "vk-header-shop-item-left"
    }, ___EmotionJSX("div", {
      className: "vk-header-shop-item-image",
      style: {
        backgroundImage: "url(".concat(getItemPictureUrl(el), ")")
      }
    }), ___EmotionJSX("div", {
      className: "vk-header-shop-item-name"
    }, el.name, ___EmotionJSX("span", null, "\xA0x\xA0", el.amount))), ___EmotionJSX("div", {
      className: "vk-header-shop-item-right"
    }, ___EmotionJSX("div", {
      className: "vk-header-shop-item-price"
    }, "\u20AC ", el.price.toFixed(2))));
  })), ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-total"
  }, ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-total-label"
  }, "Total:"), ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-total-amount"
  }, "\u20AC", shoppingCart.total.toFixed(2))), !isNegativeCashBalance && ___EmotionJSX("div", {
    tabIndex: "0",
    role: "button",
    className: "vk-header-shop-dropdown-btn",
    onClick: goToShoppingCard
  }, ___EmotionJSX(ShopIcon, null), ___EmotionJSX("div", {
    className: "vk-header-shop-dropdown-btn-label"
  }, "View Full Cart"))));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var _accounts$current;
  var shoppingCart = _ref.shoppingCart,
    accounts = _ref.accounts;
  return {
    shoppingCart: shoppingCart,
    cashWallet: (accounts === null || accounts === void 0 ? void 0 : (_accounts$current = accounts.current) === null || _accounts$current === void 0 ? void 0 : _accounts$current.wallets.find(function (el) {
      return el.walletType === walletType.CASH;
    })) || {}
  };
};
export default connect(mapStateToProps)(ShoppingCart);