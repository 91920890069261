import React from 'react';
import { GiftCodeWithStatus, redeemedByNameAccountId } from './TableDataComponents';
import { compareWithNow, displayDate } from '../../../utils/moment';
import { currencyType } from '../../../enums/wallets';
import { giftCodeStatuses } from '../../../enums/orders';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var columnsDefinition = [{
  name: 'Module Name',
  value: 'moduleName'
}, {
  name: 'Payment Type',
  value: 'paymentType',
  width: '120px'
}, {
  name: 'Redeemed On',
  render: function render(row) {
    var _displayDate;
    return ___EmotionJSX("h6", {
      className: "date-of-redeemed"
    }, (_displayDate = displayDate(row.redeemedOn, 'DD/MM/YYYY, HH:mm:ss')) !== null && _displayDate !== void 0 ? _displayDate : ___EmotionJSX("div", {
      className: "table-data__cell__no-value"
    }));
  },
  width: '120px'
}, {
  name: 'Redeemed By',
  render: redeemedByNameAccountId,
  width: '150px'
}, {
  name: "Academy ".concat(currencyType.OES),
  render: function render(row) {
    return ___EmotionJSX("div", {
      className: "academy-tokens-container"
    }, ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Reward ", currencyType.OES, ": "), row.academyCoins), row.academyCoinsRewardedOn && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Rewarded ", currencyType.OES, ": "), row.academyCoinsReward), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Reward Received On: "), displayDate(row.academyCoinsRewardedOn, 'DD/MM/YYYY, HH:mm:ss')))));
  }
}, {
  name: 'Gift Code',
  render: GiftCodeWithStatus
}, {
  name: 'Expire Date',
  render: function render(row) {
    var _displayDate2;
    var hasExpired = compareWithNow(row.expireDate) < 0;
    return ___EmotionJSX("h6", {
      className: "date-of-redeemed ".concat(hasExpired ? 'expired' : '')
    }, (_displayDate2 = displayDate(row.expireDate, 'DD/MM/YYYY, HH:mm:ss')) !== null && _displayDate2 !== void 0 ? _displayDate2 : ___EmotionJSX("div", {
      className: "table-data__cell__no-value"
    }));
  },
  width: '120px'
}];
export var optionsGiftCodeStatus = [{
  label: 'Gift Code Status',
  value: null
}, {
  label: 'Not Redeemed',
  value: giftCodeStatuses.NotRedeemed
}, {
  label: 'Redeemed',
  value: giftCodeStatuses.Redeemed
}, {
  label: 'Not Paid',
  value: giftCodeStatuses.Unpaid
}];