import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _orderBy from "lodash/orderBy";
var _orderStatusMap;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import currencyByType from '../../../utils/currencyByType';
import { walletType } from '../../../enums/wallets';
import { orderStatus } from '../../../enums/orders';
import { addDays } from '../../../utils/moment';
import ColorBadge from '../../../components/UI/Shared/ColorBadge';
import { jsx as ___EmotionJSX } from "@emotion/react";
var orderStatusMap = (_orderStatusMap = {}, _defineProperty(_orderStatusMap, orderStatus.Pending, {
  text: 'Pending',
  color: 'blue'
}), _defineProperty(_orderStatusMap, orderStatus.Paid, {
  text: 'Paid',
  color: 'green'
}), _defineProperty(_orderStatusMap, orderStatus.Rejected, {
  text: 'Rejected',
  color: 'red'
}), _orderStatusMap);
export var OrderHistoryColumn = function OrderHistoryColumn(expirationDays) {
  return [{
    name: 'Order Id',
    value: 'id',
    width: '100px'
  }, {
    name: 'Name',
    render: function render(row) {
      return ___EmotionJSX("div", {
        className: "order-history-colum-wrap"
      }, (_orderBy(row.packages, 'name') || []).map(function (el) {
        return ___EmotionJSX("div", {
          key: "package-".concat(el.name)
        }, ___EmotionJSX("b", null, el.name));
      }));
    }
  }, {
    name: 'Payment Type',
    value: 'paymentTypeId',
    width: '140px'
  }, {
    name: 'Expiration Date',
    render: function render(row) {
      return ___EmotionJSX("span", null, addDays(expirationDays, row.createdOn).format('DD-MM-YYYY'));
    },
    width: '140px'
  }, {
    name: 'Amount',
    render: function render(amount) {
      return ___EmotionJSX("span", null, " ", currencyByType(amount.totalAmount, walletType.CASH));
    },
    width: '100px'
  }, {
    name: 'Status',
    render: function render(row) {
      var _orderStatusMap$row$s, _orderStatusMap$row$s2;
      return ___EmotionJSX(ColorBadge, {
        type: (_orderStatusMap$row$s = orderStatusMap[row.status]) === null || _orderStatusMap$row$s === void 0 ? void 0 : _orderStatusMap$row$s.color
      }, (_orderStatusMap$row$s2 = orderStatusMap[row.status]) === null || _orderStatusMap$row$s2 === void 0 ? void 0 : _orderStatusMap$row$s2.text);
    },
    width: '120px'
  }];
};
export var orderStatusOptions = [{
  label: 'All',
  value: null
}, {
  value: orderStatus.Pending,
  label: 'Pending'
}, {
  value: orderStatus.Paid,
  label: 'Paid'
}, {
  value: orderStatus.Rejected,
  label: 'Rejected'
}];