import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _walletTransactionTit, _walletTypeDisplayNam, _walletTransactionsLi;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var walletType = {
  COIN: 'Coin',
  COINLEGACY: 'CoinLegacy',
  OESP: 'OESP',
  CASHSAFE: 'Cashsafe',
  CASH: 'Cash',
  BV: 'BusinessVolume',
  GAS: 'Gas',
  RESERVECASH: 'ReserveCash',
  CHARITY: 'Charity'
};
export var currencyType = {
  EURO: '€',
  EURO_LETTERS: 'EUR',
  COIN: 'ONE',
  COINLEGACY: 'ONE',
  BVP: 'BVP',
  GAS: 'GAS',
  OESP: 'OESP',
  OES: 'OES'
};
export var transactionStatus = {
  Completed: 'Completed',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Blocked: 'Blocked'
};
export var transactionType = {
  Outbound: 'Outbound',
  Inbound: 'Inbound'
};
export var withdrawalStatus = {
  Pending: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled'
};
export var topUpsStatus = {
  Pending: 'Pending',
  Confirmed: 'Confirmed',
  Cancelled: 'Cancelled',
  Refunded: 'Refunded',
  Completed: 'Completed',
  CouldNotComplete: 'CouldNotComplete'
};
export var walletTransactionTitle = (_walletTransactionTit = {}, _defineProperty(_walletTransactionTit, walletType.COIN, 'ONE Transactions'), _defineProperty(_walletTransactionTit, walletType.COINLEGACY, 'ONE (deprecated) Transactions'), _defineProperty(_walletTransactionTit, walletType.OESP, 'OESP Transactions'), _defineProperty(_walletTransactionTit, walletType.CASH, 'Cash Transactions'), _defineProperty(_walletTransactionTit, walletType.GAS, 'Gas Transactions'), _defineProperty(_walletTransactionTit, walletType.RESERVECASH, 'Reserve Cash Transactions'), _defineProperty(_walletTransactionTit, walletType.CHARITY, 'Charity Transactions'), _walletTransactionTit);
export var walletTypeDisplayName = (_walletTypeDisplayNam = {}, _defineProperty(_walletTypeDisplayNam, walletType.COIN, 'Coin'), _defineProperty(_walletTypeDisplayNam, walletType.CASH, 'Cash'), _defineProperty(_walletTypeDisplayNam, walletType.RESERVECASH, 'Reserve Cash'), _walletTypeDisplayNam);
export var walletTransactionsLink = (_walletTransactionsLi = {}, _defineProperty(_walletTransactionsLi, walletType.COIN, '/wallet/coin-transaction-history'), _defineProperty(_walletTransactionsLi, walletType.COINLEGACY, '/wallet/coin-legacy-transaction-history'), _defineProperty(_walletTransactionsLi, walletType.OESP, '/wallet/oesp-transaction-history'), _defineProperty(_walletTransactionsLi, walletType.CASH, '/wallet/cash-transaction-history'), _defineProperty(_walletTransactionsLi, walletType.CASHSAFE, '/wallet/cash-safe'), _defineProperty(_walletTransactionsLi, walletType.GAS, '/wallet/gas-transaction-history'), _defineProperty(_walletTransactionsLi, walletType.CHARITY, '/wallet/charity-transaction-history'), _walletTransactionsLi);