import { RESOURCE_MEDIA_TYPES } from '../resourcesConfig';
export var imaToolsDataAdminOnly = [{
  id: 2,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'One Academy Educational and Legal Packages User Manual',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy Educational And Legal Packages User Manual view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy Educational And Legal Packages User Manual download.pdf'
}, {
  id: 3,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'One Academy/ OneForex User Manual',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy: OneForex User Manual view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneAcademy: OneForex User Manual download.pdf'
}, {
  id: 4,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Cryptocurrency and MLM regulations',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Cryptocurrency And MLM Regulations view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Cryptocurrency And MLM Regulations download.pdf'
}, {
  id: 5,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'OneEcosystem Bonus Generating Opportunities',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Bonus Generating Opportunities view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/OneLife Bonus Generating Opportunities download.jpg'
}, {
  id: 6,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'How to qualify for Matching Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Matching Bonus view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Matching Bonus download.jpg'
}, {
  id: 7,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'How to qualify for Direct Sales Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Direct Sales Bonus view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Direct Sales Bonus download.png'
}, {
  id: 8,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'How to qualify for Network Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Network Bonus view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How To Qualify For Network Bonus download.jpg'
}, {
  id: 9,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'How to qualify for Start Up Bonus?',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/How to qualify for startup_bonus_view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/How to qualify for startup_bonus_download.png'
}, {
  id: 10,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'Leadership Plan : Sapphire',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Sapphire view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Sapphire download.jpg'
}, {
  id: 11,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'Leadership Plan : Ruby',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_ruby_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_ruby_download.jpg'
}, {
  id: 12,
  type: RESOURCE_MEDIA_TYPES.PNG,
  title: 'Leadership Plan : Emerald',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Emerald view.png',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Emerald download.png'
}, {
  id: 13,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'Leadership Plan : Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_diamond_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_diamond_download.jpg'
}, {
  id: 14,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'Leadership Plan : Black Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Black Diamond view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Black Diamond download.jpg'
}, {
  id: 15,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'Leadership Plan : Blue Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_blue_diamond_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership_Plan_blue_diamond_download.jpg'
}, {
  id: 16,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'Leadership Plan : Crown Diamond',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Crown Diamond view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Leadership Plan Crown Diamond download.jpg'
}, {
  id: 17,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'DealShaker: How Does It Work? - infographics Chinese',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_it_works__chinese_infographics_Chinese_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_it_works__chinese_infographics_Chinese_download.jpg'
}, {
  id: 18,
  type: RESOURCE_MEDIA_TYPES.JPG,
  title: 'DealShaker: How Does It Work? - infographics ENG',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_does_it_work_infographics_ENG_view.jpg',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/dealshaker_how_does_it_work_infographics_ENG_download.jpg'
}, {
  id: 19,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Business Card for IMA - Editable/WHITE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:WHITE - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:WHITE - download.pdf'
}, {
  id: 20,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Business Card for IMA - Editable/BLUE',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:BLUE - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Business Card For IMA - Editable:BLUE - download.pdf'
}, {
  id: 21,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Editable Letterhead For IMAs',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Editable Letterhead For IMAs - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Editable Letterhead For IMAs - download.pdf'
}, {
  id: 22,
  type: RESOURCE_MEDIA_TYPES.PDF,
  title: 'Branded Envelope IMA - For Print',
  viewUrl: 'https://onelife.blob.core.windows.net/help-faq/Branded Envelope IMA - For Print - view.pdf',
  downloadUrl: 'https://onelife.blob.core.windows.net/help-faq/Branded Envelope IMA - For Print - download.pdf'
}];