export var transferPanelType = {
  PAYMENT: 'payment',
  TRANSFER: 'transfer'
};
export var getTransferStatusDisplayName = function getTransferStatusDisplayName(status) {
  var displayName = null;

  // Some of those statuses are changed, the original status label is commented
  // The deployment is finished and there should be only 3 statuses for all users
  // The non-edited statuses should be not visible because nobody have them
  switch (status) {
    case transferStatuses.NONE:
      // displayName = 'Open for Deployment';
      displayName = 'Ethereum';
      break;
    case transferStatuses.PAID:
      // displayName = 'Deployment Paid';
      displayName = 'Ethereum';
      break;
    case transferStatuses.PENDING:
      displayName = 'Deployment In Progress';
      break;
    case transferStatuses.TRANSFERRED:
      // displayName = 'Deployment Complete';
      displayName = 'Polygon';
      break;
    case transferStatuses.INITIATED:
      displayName = 'Deployment Initiated';
      break;
    case transferStatuses.ERROR:
      displayName = 'Deployment error';
      break;
    case transferStatuses.INITIATED_RETRY:
      displayName = 'Deployment Payment Failed';
      break;
    default:
      break;
  }
  return displayName;
};
export var transferStatuses = {
  NONE: 'None',
  INITIATED: 'TransferInitiated',
  INITIATED_RETRY: 'TransferInitiationRetriable',
  PAID: 'TransferPaid',
  PENDING: 'TransferInProgress',
  TRANSFERRED: 'Transferred',
  ERROR: 'TransferError'
};
export var pendingWindowStatus = {
  PENDING: 'Pending',
  SUCCESS: 'Success',
  FAIL: 'Fail'
};