import _isFunction from "lodash/isFunction";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import CounterInput from '../../../components/UI/Input/CounterInput';
import { ReactComponent as TrashIcn } from '../../../assets/images/layout-svg-icons/icn-remove-trash.svg';
import starterLogo from '../../../assets/images/bonuses/starter.png';
import defaultSubscriptionLogo from '../../../assets/images/logos/one-life-logо.png';
import { orderItemTypes } from '../../../enums';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CartItem = function CartItem(props) {
  var _ref, _item$picThumbnailUrl;
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onDelete = props.onDelete,
    onEdit = props.onEdit;
  var handleDeleteCartItem = function handleDeleteCartItem() {
    return onDelete(item.uuid);
  };
  return ___EmotionJSX("div", {
    className: "vk-shopping-card-item"
  }, ___EmotionJSX(Grid, {
    container: true,
    spacing: 5,
    alignItems: "center"
  }, ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 3,
    md: 4,
    lg: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-package-image",
    style: {
      backgroundImage: "url(".concat((_ref = (_item$picThumbnailUrl = item === null || item === void 0 ? void 0 : item.picThumbnailUrl) !== null && _item$picThumbnailUrl !== void 0 ? _item$picThumbnailUrl : item === null || item === void 0 ? void 0 : item.pictureUrl) !== null && _ref !== void 0 ? _ref : (item === null || item === void 0 ? void 0 : item.type) !== orderItemTypes.Subscription ? starterLogo : defaultSubscriptionLogo, ")")
    }
  })), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 9,
    md: 8,
    lg: true
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-package-info"
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-package-name"
  }, item.name), item.vendor && ___EmotionJSX("div", {
    className: "vk-shopping-card-package-checkbox"
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-checkbox-percent"
  }, item.vendor)))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: 3,
    md: 4,
    lg: "auto"
  }), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, item.type !== orderItemTypes.Subscription ? _isFunction(onEdit) ? ___EmotionJSX(CounterInput, {
    count: item.amount,
    min: 1,
    max: 100,
    onCountChange: function onCountChange(val) {
      return onEdit({
        uuid: item.uuid,
        amount: val
      });
    }
  }) : ___EmotionJSX("div", {
    className: "vk-shopping-card-package-without-checkbox"
  }, ___EmotionJSX("p", {
    className: "vk-shoping-card-package-text"
  }, "x", item.amount)) : ___EmotionJSX("div", {
    className: "vk-shopping-card-package-without-checkbox"
  }, ___EmotionJSX("p", {
    className: "vk-shoping-card-package-text"
  }, item.duration === 1 ? '1 month' : "".concat(item.duration, " months")))), ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-package-price-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-shopping-card-package-price"
  }, "\u20AC ", item.price.toFixed(2)))), _isFunction(onDelete) && ___EmotionJSX(Grid, {
    item: true,
    xs: 12,
    sm: "auto"
  }, ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    className: "vk-shopping-card-package-remove",
    onClick: handleDeleteCartItem
  }, ___EmotionJSX(TrashIcn, null)))));
};
CartItem.propTypes = {
  item: PropTypes.any,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};
export default CartItem;