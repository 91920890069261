function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import React from 'react';
import { ReactComponent as HomeIcon } from '../../../assets/images/menu-icons/icn-home.svg';
import { ReactComponent as MyProfileIcon } from '../../../assets/images/menu-icons/icn-myprofile.svg';
import { ReactComponent as GiftIcon } from '../../../assets/images/menu-icons/icn-gift.svg';
import { ReactComponent as RecognitionIcon } from '../../../assets/images/menu-icons/icn-recognition.svg';
import { ReactComponent as WalletIcon } from '../../../assets/images/menu-icons/icn-wallet.svg';
import { ReactComponent as UsersIcon } from '../../../assets/images/menu-icons/icn-users.svg';
import { ReactComponent as CartIcon } from '../../../assets/images/menu-icons/icn-cart.svg';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Config = {
  header: {
    self: {},
    items: [{
      title: 'My Profile',
      root: true,
      submenu: [{
        systemName: 'profile-overview',
        title: 'My Profile',
        page: 'profile/overview'
      }, {
        systemName: 'profile-edit',
        title: 'My Profile',
        page: 'profile/edit'
      }, {
        systemName: 'profile-send-invitation',
        title: 'My Profile',
        page: 'profile/send-invitation'
      }, {
        systemName: 'profile-public-profile',
        title: 'My Profile',
        page: 'profile/public-profile'
      }, {
        systemName: 'payment-details',
        title: 'My Profile',
        page: 'profile/payment-details'
      }, {
        systemName: 'profile-kyc',
        title: 'My Profile',
        page: 'profile/kyc'
      }, {
        systemName: 'profile-password',
        title: 'My Profile',
        page: 'profile/password'
      },
      // REMOVE TAG: HIDE SECURITY PIN
      // {
      //   title: 'My Profile',
      //   page: 'profile/security-pin',
      // },
      {
        systemName: 'profile-accounts',
        title: 'My Profile',
        page: 'profile/accounts'
      }, {
        systemName: 'profile-add-member',
        title: 'My Profile',
        page: 'profile/add-member'
      }, {
        systemName: 'profile-my-modules',
        title: 'My Profile',
        page: 'profile/my-modules'
      }, {
        systemName: 'profile-my-subscriptions',
        title: 'My Profile',
        page: 'profile/my-subscriptions'
      }, {
        systemName: 'profile-leaders-regional',
        title: 'My Profile',
        page: 'profile/leaders/regional'
      }, {
        systemName: 'profile-leaders-country-board',
        title: 'My Profile',
        page: 'profile/leaders/country-board'
      }, {
        systemName: 'wallet-one',
        title: 'My Wallets',
        page: 'wallet/one'
      }, {
        systemName: 'wallet-one-legacy',
        title: 'My Wallets',
        page: 'wallet/one-legacy'
      }, {
        systemName: 'wallet-oesp',
        title: 'My Wallets',
        page: 'wallet/oesp'
      }, {
        systemName: 'wallet-cash',
        title: 'My Wallets',
        page: 'wallet/cash'
      }, {
        systemName: 'wallet-request-withdrawal',
        title: 'My Wallets',
        page: 'wallet/request-withdrawal'
      }, {
        title: 'My Wallets',
        systemName: 'wallet-reserve-cash',
        page: 'wallet/reserve-cash'
      }, {
        title: 'My Wallets',
        systemName: 'wallet-coin-transaction-history',
        page: 'wallet/coin-transaction-history'
      }, {
        title: 'My Wallets',
        systemName: 'wallet-coin-transaction-history-archive',
        page: 'wallet/coin-transaction-history/archive'
      }, {
        title: 'My Wallets',
        systemName: 'wallet-oesp-transaction-history',
        page: 'wallet/oesp-transaction-history'
      }, {
        systemName: 'wallet-oesp-transaction-history-archive',
        title: 'My Wallets',
        page: 'wallet/oesp-transaction-history/archive'
      }, {
        systemName: 'wallet-cash-transaction-history',
        title: 'My Wallets',
        page: 'wallet/cash-transaction-history'
      }, {
        systemName: 'wallet-cash-transaction-history-archive',
        title: 'My Wallets',
        page: 'wallet/cash-transaction-history/archive'
      }, {
        systemName: 'wallet-reserve-cash-transaction-history',
        title: 'My Wallets',
        page: 'wallet/reserve-cash-transaction-history'
      }, {
        systemName: 'wallet-reserve-cash-transaction-history-archive',
        title: 'My Wallets',
        page: 'wallet/reserve-cash-transaction-history/archive'
      }, {
        systemName: 'wallet-gas-transaction-history',
        title: 'My Wallets',
        page: 'wallet/gas-transaction-history'
      }, {
        systemName: 'wallet-gas-transaction-history-archive',
        title: 'My Wallets',
        page: 'wallet/gas-transaction-history/archive'
      }, {
        systemName: 'wallet-cash-safe',
        title: 'My Wallets',
        page: 'wallet/cash-safe'
      }, {
        systemName: 'wallet-gas',
        title: 'My Wallets',
        page: 'wallet/gas'
      }, {
        systemName: 'bonus-network',
        title: 'My Bonuses',
        page: 'bonus/network'
      }, {
        systemName: 'bonus-direct-sale',
        title: 'My Bonuses',
        page: 'bonus/direct-sale'
      }, {
        systemName: 'bonus-matching',
        title: 'My Bonuses',
        page: 'bonus/matching'
      }, {
        systemName: 'bonus-start-up',
        title: 'My Bonuses',
        page: 'bonus/start-up'
      }, {
        systemName: 'bonus-network-details',
        title: 'My Bonuses',
        page: 'bonus/network/details/:id'
      }, {
        systemName: 'bonus-matching-details',
        title: 'My Bonuses',
        page: 'bonus/matching/details/:id'
      }, {
        systemName: 'my-network-binary-tree',
        title: 'My Network',
        page: 'my-network/binary-tree'
      }, {
        systemName: 'my-network-table-tree',
        title: 'My Network',
        page: 'my-network/table-tree'
      }, {
        systemName: 'my-network-community',
        title: 'My Network',
        page: 'my-network/community'
      }, {
        systemName: 'shop-orders-history',
        title: 'Shop',
        page: 'shop/orders-history'
      }, {
        systemName: 'shop-my-gift-codes',
        title: 'Shop',
        page: 'shop/my-gift-codes'
      }, {
        systemName: 'shop-all-packages',
        title: 'Shop',
        page: 'shop/all-packages'
      }, {
        systemName: 'shop-subscriptions',
        title: 'Shop',
        page: 'shop/all-subscriptions'
      }, {
        systemName: 'shop-package-details',
        title: 'Shop',
        page: 'shop/package-details/:id'
      }, {
        systemName: 'shop-cart',
        title: 'Shop',
        page: 'shop/cart'
      }, {
        systemName: 'shop-payment-methods',
        title: 'Shop',
        page: 'shop/payment-methods'
      }, {
        systemName: 'shop-order-finish',
        title: 'Shop',
        page: 'shop/order-finish'
      }, {
        systemName: 'information-center-resources',
        title: 'Information Center',
        page: 'information-center/resources'
      }, {
        systemName: 'information-center-resources-ima-tools',
        title: 'Information Center',
        page: 'information-center/resources/ima-tools'
      }, {
        systemName: 'information-center-branding-materials',
        title: 'Information Center',
        page: 'information-center/resources/branding-materials'
      }, {
        systemName: 'information-center-product-and-business-presentations',
        title: 'Information Center',
        page: 'information-center/resources/product-and-business-presentations'
      }, {
        systemName: 'information-center-videos',
        title: 'Information Center',
        page: 'information-center/resources/video'
      }, {
        systemName: 'information-center-webinars',
        title: 'Information Center',
        page: 'information-center/resources/webinars'
      }, {
        systemName: 'information-center-compliance',
        title: 'Information Center',
        page: 'information-center/compliance'
      }, {
        systemName: 'information-center-compliance-audit',
        title: 'Information Center',
        page: 'information-center/compliance/audit'
      }, {
        systemName: 'information-center-compliance-legal-documents',
        title: 'Information Center',
        page: 'information-center/compliance/legal-documents'
      }, {
        systemName: 'information-center-compliance-ima-policy',
        title: 'Information Center',
        page: 'information-center/compliance/ima-policy'
      }, {
        systemName: 'information-center-compliance-privacy-policy',
        title: 'Information Center',
        page: 'information-center/compliance/privacy-policy'
      }, {
        systemName: 'information-center-compliance-access-request-form',
        title: 'Information Center',
        page: 'information-center/compliance/access-request-form'
      }, {
        systemName: 'information-center-compliance-consent-withdrawal-form',
        title: 'Information Center',
        page: 'information-center/compliance/consent-withdrawal-form'
      }, {
        systemName: 'information-center-faqs',
        title: 'Information Center',
        page: 'information-center/faqs'
      }, {
        systemName: 'information-center-registration',
        title: 'Information Center',
        page: 'information-center/faqs/registration'
      }, {
        systemName: 'information-center-login-settings',
        title: 'Information Center',
        page: 'information-center/faqs/login-settings'
      }, {
        systemName: 'information-center-bonus-opportunities',
        title: 'Information Center',
        page: 'information-center/faqs/bonus-opportunities'
      }, {
        systemName: 'information-center-sponsorship',
        title: 'Information Center',
        page: 'information-center/faqs/sponsorship'
      }, {
        systemName: 'information-center-educational-packages-payments',
        title: 'Information Center',
        page: 'information-center/faqs/educational-packages-payments'
      }, {
        systemName: 'information-center-mining-coins-coinsafe',
        title: 'Information Center',
        page: 'information-center/faqs/mining-coins-coinsafe'
      }, {
        systemName: 'information-center-faqs-account-verification-compliance',
        title: 'Information Center',
        page: 'information-center/faqs/account-verification-compliance'
      }, {
        systemName: 'information-center-faqs-general-account-information',
        title: 'Information Center',
        page: 'information-center/faqs/general-account-information'
      }, {
        systemName: 'recognition-leadership',
        title: 'Leadership',
        page: 'recognition/leadership'
      }, {
        systemName: 'recognition-eagle',
        title: 'Eagle',
        page: 'recognition/eagle'
      }]
    }]
  },
  aside: {
    self: {},
    items: [{
      separator: true
    }, {
      systemName: 'dashboard',
      title: 'Dashboard',
      desc: 'Dashboard',
      root: true,
      page: 'dashboard',
      svg: ___EmotionJSX(HomeIcon, null),
      translate: 'MENU.DASHBOARD'
    }, {
      systemName: 'profile',
      title: 'My Profile',
      desc: 'My Profile',
      root: true,
      page: 'profile',
      svg: ___EmotionJSX(MyProfileIcon, null)
    }, {
      systemName: 'bonus',
      title: 'My Bonuses',
      desc: 'My Bonuses',
      root: true,
      page: 'bonus',
      svg: ___EmotionJSX(GiftIcon, null)
    }, {
      systemName: 'recognition',
      title: 'Recognition',
      desc: 'Recognition',
      root: true,
      page: 'recognition',
      svg: ___EmotionJSX(RecognitionIcon, null)
    }, {
      systemName: 'wallet',
      title: 'My Wallets',
      desc: 'My Wallets',
      root: true,
      page: 'wallet',
      svg: ___EmotionJSX(WalletIcon, null)
    }, {
      systemName: 'my-network',
      title: 'My Network',
      desc: 'My Network',
      root: true,
      page: 'my-network',
      svg: ___EmotionJSX(UsersIcon, null)
    }, {
      systemName: 'shop',
      title: 'Shop',
      desc: 'Shop',
      root: true,
      page: 'shop',
      svg: ___EmotionJSX(CartIcon, null)
    }]
  }
};
var getOverridenItems = function getOverridenItems(overrides, source) {
  var overridenItemPaths = overrides.map(function (i) {
    return i.systemName;
  });
  var itemsOverride = source.map(function (item) {
    var overridenItemIndex = overridenItemPaths.indexOf(item.systemName);
    return overridenItemIndex > -1 ? Object.assign({}, item, overrides[overridenItemIndex]) : item;
  });
  return itemsOverride;
};
export var getMenuConfig = function getMenuConfig(itemsOverrides) {
  var header = itemsOverrides.header,
    aside = itemsOverrides.aside;
  var overridenHeaderSubmenuItems = getOverridenItems(header, Config.header.items[0].submenu);
  var overridenAsideItems = getOverridenItems(aside, Config.aside.items);
  var overrideConfig = Object.assign({}, Config, {
    header: {
      self: _objectSpread({}, Config.header.self),
      items: [Object.assign({}, Config.header.items[0], {
        submenu: _toConsumableArray(overridenHeaderSubmenuItems)
      })]
    },
    aside: {
      self: _objectSpread({}, Config.header.self),
      items: [{
        separator: true
      }].concat(_toConsumableArray(overridenAsideItems))
    }
  });
  return overrideConfig;
};
export var getOverrideMenuConfig = getMenuConfig;
export default Config;