import React from 'react';
import { ReactComponent as BvIcn } from '../../../assets/images/layout-svg-icons/icn-briefcase.svg';
import { ReactComponent as TokensIcn } from '../../../assets/images/layout-svg-icons/icn-token.svg';
import { currencyType } from '../../../enums';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackageDetailsView = function PackageDetailsView(_ref) {
  var _packageData$academyC;
  var packageData = _ref.packageData;
  return ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-wrap"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(BvIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Business Volume")), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, packageData === null || packageData === void 0 ? void 0 : packageData.businessVolume)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(TokensIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Included ", currencyType.OES)), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, packageData === null || packageData === void 0 ? void 0 : packageData.coins)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item"
  }, ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-icon"
  }, ___EmotionJSX(TokensIcn, null)), ___EmotionJSX("div", {
    className: "vk-shop-package-details-infobox-left-item-text"
  }, ___EmotionJSX("h4", {
    className: "vk-shop-package-details-infobox-left-item-text-title"
  }, "Academy ", currencyType.OES)), ___EmotionJSX("h2", {
    className: "vk-shop-package-details-infobox-left-item-amount"
  }, (_packageData$academyC = packageData === null || packageData === void 0 ? void 0 : packageData.academyCoins) !== null && _packageData$academyC !== void 0 ? _packageData$academyC : 0)));
};
export default PackageDetailsView;