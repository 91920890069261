import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _pick from "lodash/pick";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import queryString from 'query-string';
import { giftCodeStatuses } from '../../enums/orders';
export var getAllPackagesModel = function getAllPackagesModel(data) {
  return (data === null || data === void 0 ? void 0 : data.catalogueItems) && {
    catalogueItems: data.catalogueItems,
    total: data.total
  };
};
export var getAllActivePackagesParams = function getAllActivePackagesParams(options) {
  var filterField = [];
  var filterValue = [];
  var type = options.type,
    active = options.active,
    visible = options.visible;
  if (type) {
    filterField.push('type');
    filterValue.push(type);
  }
  if (active) {
    filterField.push('active');
    filterValue.push(active);
  }
  if (visible) {
    filterField.push('visible');
    filterValue.push(visible);
  }
  return queryString.stringify(_objectSpread({}, filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var getMyAllGiftCodesParams = function getMyAllGiftCodesParams(options) {
  var giftCode = options.giftCode,
    _options$giftCodeStat = options.giftCodeStatus,
    giftCodeStatus = _options$giftCodeStat === void 0 ? giftCodeStatuses.NotRedeemed : _options$giftCodeStat,
    packageName = options.packageName;
  var filterField = [];
  var filterValue = [];
  if (giftCode !== null && giftCode !== void 0 && giftCode.length) {
    filterField.push('giftCode');
    filterValue.push(giftCode);
  }
  if (giftCodeStatus) {
    filterField.push('giftCodeStatus');
    filterValue.push(giftCodeStatus);
  }
  if (packageName) {
    filterField.push('packageName');
    filterValue.push(packageName);
  }
  return queryString.stringify(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};